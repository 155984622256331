function Contact(){
    return(
        <div style={{
            textAlign: 'center'
        }}>
            <h1>Contact</h1>
            <br/>
            <p style={{marginBottom:'0px'}}>email:</p>
            <p style={{marginTop:'5px'}}>andykawabata@gmail.com</p>
            <br/>
            <br/>
            <a 
                href='https://www.linkedin.com/in/andy-kawabata-52a001196/'
                style={{
                    textDecoration: 'none',
                    color: '#0073b1'}}>
            LinkedIn
            </a>
            <br/>
            <br/>
            <br/>
        </div>
    )
}

export default Contact